/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { PageSottoSezioneDTO } from '../model/pageSottoSezioneDTO';
import { SottoSezione } from '../model/sottoSezione';
import { SottoSezioneDTO } from '../model/sottoSezioneDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SottoSezioniService {

    protected basePath = 'https://dev-api.anmco.it/anmco';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Crea una sotto sezione assegnandola ad una specifica sezione.
     * 
     * @param body Sotto sezione da aggiungere.
     * @param sezione_id Identificativo della sezione a cui assegnare la sotto sezione da aggiungere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSottoSezione(body: SottoSezioneDTO, sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<SottoSezione>;
    public addSottoSezione(body: SottoSezioneDTO, sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SottoSezione>>;
    public addSottoSezione(body: SottoSezioneDTO, sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SottoSezione>>;
    public addSottoSezione(body: SottoSezioneDTO, sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addSottoSezione.');
        }

        if (sezione_id === null || sezione_id === undefined) {
            throw new Error('Required parameter sezione_id was null or undefined when calling addSottoSezione.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sezione_id !== undefined && sezione_id !== null) {
            queryParameters = queryParameters.set('sezione_id', <any>sezione_id);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SottoSezione>('post',`${this.basePath}/api/sotto-sezioni`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una sotto sezione a partire dal suo identificativo.
     * 
     * @param sotto_sezione_id Identificativo della sotto sezione da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSottoSezione(sotto_sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<SottoSezioneDTO>;
    public getSottoSezione(sotto_sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SottoSezioneDTO>>;
    public getSottoSezione(sotto_sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SottoSezioneDTO>>;
    public getSottoSezione(sotto_sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sotto_sezione_id === null || sotto_sezione_id === undefined) {
            throw new Error('Required parameter sotto_sezione_id was null or undefined when calling getSottoSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SottoSezioneDTO>('get',`${this.basePath}/api/sotto-sezioni/${encodeURIComponent(String(sotto_sezione_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una sotto sezione a partire dal suo identificativo, se abilitata.
     * 
     * @param sotto_sezione_id Identificativo della sotto sezione da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSottoSezioneOnline(sotto_sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<SottoSezioneDTO>;
    public getSottoSezioneOnline(sotto_sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SottoSezioneDTO>>;
    public getSottoSezioneOnline(sotto_sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SottoSezioneDTO>>;
    public getSottoSezioneOnline(sotto_sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sotto_sezione_id === null || sotto_sezione_id === undefined) {
            throw new Error('Required parameter sotto_sezione_id was null or undefined when calling getSottoSezioneOnline.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SottoSezioneDTO>('get',`${this.basePath}/api/sotto-sezioni/${encodeURIComponent(String(sotto_sezione_id))}/online`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di tutte le sotto sezioni afferenti una specifica sezione.
     * 
     * @param sezione_id Identificativo della sezione a cui assegnare la sotto sezione da aggiungere. Non può essere nullo o vuoto.
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSottoSezioni(sezione_id: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageSottoSezioneDTO>;
    public getSottoSezioni(sezione_id: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSottoSezioneDTO>>;
    public getSottoSezioni(sezione_id: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSottoSezioneDTO>>;
    public getSottoSezioni(sezione_id: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sezione_id === null || sezione_id === undefined) {
            throw new Error('Required parameter sezione_id was null or undefined when calling getSottoSezioni.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (sezione_id !== undefined && sezione_id !== null) {
            queryParameters = queryParameters.set('sezione_id', <any>sezione_id);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageSottoSezioneDTO>('get',`${this.basePath}/api/sotto-sezioni`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di tutte le sotto sezioni abilitate afferenti una specifica sezione ed una specifica macro sezione.
     * 
     * @param sezione_slug Slug della sezione. Non può essere nullo o vuoto.
     * @param macro_sezione_slug Slug della macro sezione. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSottoSezioniOnline(sezione_slug: string, macro_sezione_slug: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SottoSezioneDTO>>;
    public getSottoSezioniOnline(sezione_slug: string, macro_sezione_slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SottoSezioneDTO>>>;
    public getSottoSezioniOnline(sezione_slug: string, macro_sezione_slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SottoSezioneDTO>>>;
    public getSottoSezioniOnline(sezione_slug: string, macro_sezione_slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sezione_slug === null || sezione_slug === undefined) {
            throw new Error('Required parameter sezione_slug was null or undefined when calling getSottoSezioniOnline.');
        }

        if (macro_sezione_slug === null || macro_sezione_slug === undefined) {
            throw new Error('Required parameter macro_sezione_slug was null or undefined when calling getSottoSezioniOnline.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sezione_slug !== undefined && sezione_slug !== null) {
            queryParameters = queryParameters.set('sezione_slug', <any>sezione_slug);
        }
        if (macro_sezione_slug !== undefined && macro_sezione_slug !== null) {
            queryParameters = queryParameters.set('macro_sezione_slug', <any>macro_sezione_slug);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<SottoSezioneDTO>>('get',`${this.basePath}/api/sotto-sezioni/online`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove una sotto sezione.
     * 
     * @param sotto_sezione_id Identificativo della sotto sezione da rimuovere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeSottoSezione(sotto_sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public removeSottoSezione(sotto_sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public removeSottoSezione(sotto_sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public removeSottoSezione(sotto_sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sotto_sezione_id === null || sotto_sezione_id === undefined) {
            throw new Error('Required parameter sotto_sezione_id was null or undefined when calling removeSottoSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/sotto-sezioni/${encodeURIComponent(String(sotto_sezione_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna una sotto sezione.
     * Consente la modifica delle sole informazioni della sotto sezione, senza impattare sulla sezione e sulle pagine e link
     * @param body Sotto sezione da aggiornare.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSottoSezione(body: SottoSezioneDTO, observe?: 'body', reportProgress?: boolean): Observable<SottoSezione>;
    public updateSottoSezione(body: SottoSezioneDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SottoSezione>>;
    public updateSottoSezione(body: SottoSezioneDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SottoSezione>>;
    public updateSottoSezione(body: SottoSezioneDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSottoSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SottoSezione>('put',`${this.basePath}/api/sotto-sezioni`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna la sezione di una sotto sezione.
     * Consente lo spostamento di una sotto sezione sotto una diversa sezione, entrambe indicate dai rispettivi identificativi
     * @param sotto_sezione_id Identificativo della sezione da spostare. Non può essere nullo o vuoto.
     * @param sezione_id Identificativo della nuova sezione a cui assegnare la sotto sezione da spostare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSottoSezioneInSezione(sotto_sezione_id: string, sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<SottoSezione>;
    public updateSottoSezioneInSezione(sotto_sezione_id: string, sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SottoSezione>>;
    public updateSottoSezioneInSezione(sotto_sezione_id: string, sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SottoSezione>>;
    public updateSottoSezioneInSezione(sotto_sezione_id: string, sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sotto_sezione_id === null || sotto_sezione_id === undefined) {
            throw new Error('Required parameter sotto_sezione_id was null or undefined when calling updateSottoSezioneInSezione.');
        }

        if (sezione_id === null || sezione_id === undefined) {
            throw new Error('Required parameter sezione_id was null or undefined when calling updateSottoSezioneInSezione.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sezione_id !== undefined && sezione_id !== null) {
            queryParameters = queryParameters.set('sezione_id', <any>sezione_id);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SottoSezione>('put',`${this.basePath}/api/sotto-sezioni/${encodeURIComponent(String(sotto_sezione_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
