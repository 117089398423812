import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';
import {SnackbarComponent, SnackbarTypes} from "../component/snackbar/snackbar.component";


@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  loadingScreen: boolean | undefined


  constructor(public snackBar: MatSnackBar,
  ) {
  }

  openStyledSnackBar(message: string, type?: SnackbarTypes, horizontalPosition?: MatSnackBarHorizontalPosition, verticalPosition?: MatSnackBarVerticalPosition, duration?: number) {
    const defaultType = !!type ? type : SnackbarTypes.Error;
    const config: MatSnackBarConfig = new MatSnackBarConfig();
    config.verticalPosition = !!verticalPosition ? verticalPosition : 'bottom';
    config.horizontalPosition = !!horizontalPosition ? horizontalPosition : 'center';
    config.duration = !!duration ? duration : 5000;
    config.data = {message: message, type: defaultType};
    config.panelClass = this.getPanelClass(defaultType);
    this.snackBar.openFromComponent(SnackbarComponent, config,);
  }

  getPanelClass(type: SnackbarTypes) {
    switch (type) {
      case SnackbarTypes.Success:
        return 'mat-bg-green';
      case SnackbarTypes.Error:
        return 'mat-bg-strong-red';
      case SnackbarTypes.Warning:
        return 'mat-bg-yellow-500';
      case SnackbarTypes.SuccessGreen:
        return 'mat-bg-green';
      default:
        return 'error';
    }
  }

  setLoadingScreen(value: boolean) {
    setTimeout(() => this.loadingScreen = value, 0)
  }


}
