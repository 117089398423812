export * from './accessGroups.service';
import { AccessGroupsService } from './accessGroups.service';
export * from './altreAttivita.service';
import { AltreAttivitaService } from './altreAttivita.service';
export * from './atti2019.service';
import { Atti2019Service } from './atti2019.service';
export * from './atti2020.service';
import { Atti2020Service } from './atti2020.service';
export * from './atti2021.service';
import { Atti2021Service } from './atti2021.service';
export * from './attivita.service';
import { AttivitaService } from './attivita.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './categorie.service';
import { CategorieService } from './categorie.service';
export * from './contents.service';
import { ContentsService } from './contents.service';
export * from './contenutiEsterni.service';
import { ContenutiEsterniService } from './contenutiEsterni.service';
export * from './contenutiPagina.service';
import { ContenutiPaginaService } from './contenutiPagina.service';
export * from './eventi.service';
import { EventiService } from './eventi.service';
export * from './eventiMultimediali.service';
import { EventiMultimedialiService } from './eventiMultimediali.service';
export * from './keywords.service';
import { KeywordsService } from './keywords.service';
export * from './links.service';
import { LinksService } from './links.service';
export * from './macroSezioni.service';
import { MacroSezioniService } from './macroSezioni.service';
export * from './media.service';
import { MediaService } from './media.service';
export * from './news.service';
import { NewsService } from './news.service';
export * from './pagine.service';
import { PagineService } from './pagine.service';
export * from './sezioni.service';
import { SezioniService } from './sezioni.service';
export * from './sliders.service';
import { SlidersService } from './sliders.service';
export * from './sottoSezioni.service';
import { SottoSezioniService } from './sottoSezioni.service';
export * from './strutture.service';
import { StruttureService } from './strutture.service';
export * from './studi.service';
import { StudiService } from './studi.service';
export * from './templates.service';
import { TemplatesService } from './templates.service';
export * from './urlRedirect.service';
import { UrlRedirectService } from './urlRedirect.service';
export * from './utente.service';
import { UtenteService } from './utente.service';
export * from './utilities.service';
import { UtilitiesService } from './utilities.service';
export const APIS = [AccessGroupsService, AltreAttivitaService, Atti2019Service, Atti2020Service, Atti2021Service, AttivitaService, AuthService, CategorieService, ContentsService, ContenutiEsterniService, ContenutiPaginaService, EventiService, EventiMultimedialiService, KeywordsService, LinksService, MacroSezioniService, MediaService, NewsService, PagineService, SezioniService, SlidersService, SottoSezioniService, StruttureService, StudiService, TemplatesService, UrlRedirectService, UtenteService, UtilitiesService];
