export enum PathEnum {
  HOME = 'home',
  PAGE = 'pages',
  LOGIN = 'login',
  SEARCH = 'search',
  PRIVACYPOLICY = 'privacy-policy',
  LEGALPRIVACY = 'legal-privacy',
  AREARISERVATA = 'area-riservata',
  GESTIONEEVENTI = 'gestione-eventi'
}

export enum MacroSezione {
  ASSOCIAZIONE = 'l-associazione',
  FORMAZIONE = 'formazione',
  PUBBLICAZIONI = 'pubblicazioni',
  CONTENUTI_SCIENTIFICI = 'contenuti-scientifici',
  TUTTOILSITO = 'all',
}

export enum PathMacroSezione {
  ASSOCIAZIONE =  '/pages/l-associazione',
  FORMAZIONE = '/pages/formazione',
  PUBBLICAZIONI = '/pages/pubblicazioni',
  CONTENUTI_SCIENTIFICI = '/pages/contenuti-scientifici',
  ELENCO_NEWS = '/pages/news/elenco-news'
}
