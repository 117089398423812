/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Modalità di apertura supportate
 */
export type AperturaType = 'NEW_TAB' | 'IN_PAGE';

export const AperturaType = {
    NEWTAB: 'NEW_TAB' as AperturaType,
    INPAGE: 'IN_PAGE' as AperturaType
};