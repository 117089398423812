import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, takeUntil} from 'rxjs';
import {HttpCancelService} from "../services/http-cancel-service.service";

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
  constructor(private httpCancelService: HttpCancelService) { }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return req.url.includes(anmcoSession) ? next.handle(req) : next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()))
  }
}

export const anmcoSession = 'auth/anmco-session'
