/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di contenuto supportati
 */
export type ContentType = 'SOTTOSEZIONE' | 'PAGINA' | 'LINK' | 'CONTENUTO_ESTERNO';

export const ContentType = {
    SOTTOSEZIONE: 'SOTTOSEZIONE' as ContentType,
    PAGINA: 'PAGINA' as ContentType,
    LINK: 'LINK' as ContentType,
    CONTENUTOESTERNO: 'CONTENUTO_ESTERNO' as ContentType
};