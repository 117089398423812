import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccessGroupsService } from './api/accessGroups.service';
import { AltreAttivitaService } from './api/altreAttivita.service';
import { Atti2019Service } from './api/atti2019.service';
import { Atti2020Service } from './api/atti2020.service';
import { Atti2021Service } from './api/atti2021.service';
import { AttivitaService } from './api/attivita.service';
import { AuthService } from './api/auth.service';
import { CategorieService } from './api/categorie.service';
import { ContentsService } from './api/contents.service';
import { ContenutiEsterniService } from './api/contenutiEsterni.service';
import { ContenutiPaginaService } from './api/contenutiPagina.service';
import { EventiService } from './api/eventi.service';
import { EventiMultimedialiService } from './api/eventiMultimediali.service';
import { KeywordsService } from './api/keywords.service';
import { LinksService } from './api/links.service';
import { MacroSezioniService } from './api/macroSezioni.service';
import { MediaService } from './api/media.service';
import { NewsService } from './api/news.service';
import { PagineService } from './api/pagine.service';
import { SezioniService } from './api/sezioni.service';
import { SlidersService } from './api/sliders.service';
import { SottoSezioniService } from './api/sottoSezioni.service';
import { StruttureService } from './api/strutture.service';
import { StudiService } from './api/studi.service';
import { TemplatesService } from './api/templates.service';
import { UrlRedirectService } from './api/urlRedirect.service';
import { UtenteService } from './api/utente.service';
import { UtilitiesService } from './api/utilities.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccessGroupsService,
    AltreAttivitaService,
    Atti2019Service,
    Atti2020Service,
    Atti2021Service,
    AttivitaService,
    AuthService,
    CategorieService,
    ContentsService,
    ContenutiEsterniService,
    ContenutiPaginaService,
    EventiService,
    EventiMultimedialiService,
    KeywordsService,
    LinksService,
    MacroSezioniService,
    MediaService,
    NewsService,
    PagineService,
    SezioniService,
    SlidersService,
    SottoSezioniService,
    StruttureService,
    StudiService,
    TemplatesService,
    UrlRedirectService,
    UtenteService,
    UtilitiesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
