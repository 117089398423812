import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafeHtmlPipe} from "./safe-html.pipe";
import {DateFormatPipe} from "./date-format.pipe";
import {ConvertToNumberPipe} from "./convert-to-number.pipe";
import {ConvertiDatePipe} from "./converti-date.pipe";
import {SafeUrlPipe} from "./safe-url.pipe";
import { RegionePipe } from './regione.pipe';
import { ReplaceDashPipe } from './replace-dash.pipe';



@NgModule({
  declarations: [
    SafeHtmlPipe,
    DateFormatPipe,
    ConvertToNumberPipe,
    ConvertiDatePipe,
    SafeUrlPipe,
    RegionePipe,
    ReplaceDashPipe
  ],
    exports: [
        SafeHtmlPipe,
        DateFormatPipe,
        ConvertToNumberPipe,
        ConvertiDatePipe,
        SafeUrlPipe,
        RegionePipe,
        ReplaceDashPipe
    ],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
