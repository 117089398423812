/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { MacroSezione } from '../model/macroSezione';
import { MacroSezioneDTO } from '../model/macroSezioneDTO';
import { PageMacroSezioneDTO } from '../model/pageMacroSezioneDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MacroSezioniService {

    protected basePath = 'https://dev-api.anmco.it/anmco';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Crea una macro sezione.
     * 
     * @param body Macro sezione da aggiungere.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMacroSezione(body: MacroSezioneDTO, observe?: 'body', reportProgress?: boolean): Observable<MacroSezione>;
    public addMacroSezione(body: MacroSezioneDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MacroSezione>>;
    public addMacroSezione(body: MacroSezioneDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MacroSezione>>;
    public addMacroSezione(body: MacroSezioneDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addMacroSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MacroSezione>('post',`${this.basePath}/api/macro-sezioni`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una macro sezione a partire dal suo identificativo.
     * 
     * @param macro_sezione_id Identificativo della macro sezione da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMacroSezione(macro_sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<MacroSezioneDTO>;
    public getMacroSezione(macro_sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MacroSezioneDTO>>;
    public getMacroSezione(macro_sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MacroSezioneDTO>>;
    public getMacroSezione(macro_sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (macro_sezione_id === null || macro_sezione_id === undefined) {
            throw new Error('Required parameter macro_sezione_id was null or undefined when calling getMacroSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MacroSezioneDTO>('get',`${this.basePath}/api/macro-sezioni/${encodeURIComponent(String(macro_sezione_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una macro sezione a partire dal suo identificativo, se abilitata.
     * 
     * @param macro_sezione_slug Slug della macro sezione da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMacroSezioneOnline(macro_sezione_slug: string, observe?: 'body', reportProgress?: boolean): Observable<MacroSezioneDTO>;
    public getMacroSezioneOnline(macro_sezione_slug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MacroSezioneDTO>>;
    public getMacroSezioneOnline(macro_sezione_slug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MacroSezioneDTO>>;
    public getMacroSezioneOnline(macro_sezione_slug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (macro_sezione_slug === null || macro_sezione_slug === undefined) {
            throw new Error('Required parameter macro_sezione_slug was null or undefined when calling getMacroSezioneOnline.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MacroSezioneDTO>('get',`${this.basePath}/api/macro-sezioni/${encodeURIComponent(String(macro_sezione_slug))}/online`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di tutte le macro sezioni.
     * 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMacroSezioni(page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageMacroSezioneDTO>;
    public getMacroSezioni(page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMacroSezioneDTO>>;
    public getMacroSezioni(page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMacroSezioneDTO>>;
    public getMacroSezioni(page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageMacroSezioneDTO>('get',`${this.basePath}/api/macro-sezioni`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di tutte le macro sezioni abilitate.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMacroSezioniOnline(observe?: 'body', reportProgress?: boolean): Observable<Array<MacroSezioneDTO>>;
    public getMacroSezioniOnline(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MacroSezioneDTO>>>;
    public getMacroSezioniOnline(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MacroSezioneDTO>>>;
    public getMacroSezioniOnline(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MacroSezioneDTO>>('get',`${this.basePath}/api/macro-sezioni/online`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove una macro sezione.
     * 
     * @param macro_sezione_id Identificativo della macro sezione da rimuovere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeMacroSezione(macro_sezione_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public removeMacroSezione(macro_sezione_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public removeMacroSezione(macro_sezione_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public removeMacroSezione(macro_sezione_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (macro_sezione_id === null || macro_sezione_id === undefined) {
            throw new Error('Required parameter macro_sezione_id was null or undefined when calling removeMacroSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/macro-sezioni/${encodeURIComponent(String(macro_sezione_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna una macro sezione.
     * Consente la modifica delle sole informazioni della macro sezione, senza impattare sulle sezioni
     * @param body Macro sezione da aggiornare.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMacroSezione(body: MacroSezioneDTO, observe?: 'body', reportProgress?: boolean): Observable<MacroSezione>;
    public updateMacroSezione(body: MacroSezioneDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MacroSezione>>;
    public updateMacroSezione(body: MacroSezioneDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MacroSezione>>;
    public updateMacroSezione(body: MacroSezioneDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateMacroSezione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MacroSezione>('put',`${this.basePath}/api/macro-sezioni`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
