import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError, from, timer} from 'rxjs';
import {catchError, distinctUntilChanged, filter, mergeMap, switchMap} from 'rxjs/operators';
import {LocalStorageService} from "../services/local-storage.service";
import {AuthService, GrantType} from "../../api-clients/generated/services";
import {Router} from "@angular/router";
import {PathEnum} from "../../app/pages/path-enum";
import {UtilitiesService} from "../services/utilities.service";
import {SnackbarTypes} from "../component/snackbar/snackbar.component";
import {ServiziAnmcoService} from "../services/servizi-anmco.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    isTokenRefreshing: BehaviorSubject<boolean | undefined> | undefined;

    /**
     * Constructor
     */
    constructor(private localStorageService: LocalStorageService,
                private authService: AuthService,
                private utilitiesService: UtilitiesService,
                private serviziAnmcoService: ServiziAnmcoService,
                private router: Router) {
    }

    /**
     * Intercept
     *
     * @param httpRequest
     * @param next
     */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (httpRequest.url.includes('login') || httpRequest.url.includes('token')) {
            console.log('Intercettata chiamata di login o refresh token, non utilizzo interceptor');
            return next.handle(httpRequest);
        }


        let authReq = httpRequest.clone();
        if (!!this.localStorageService.getAccessToken()) {
            authReq = authReq.clone({
                headers: httpRequest.headers.set('Authorization', 'Bearer ' + this.localStorageService.getAccessToken())
            });
        }

        if (httpRequest.method !== 'OPTIONS') {
            return this.handleRequest(authReq, next);
        } else {
            return next.handle(authReq);
        }
    }

    handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(
                (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        if (!!this.isTokenRefreshing) {
                            console.log('isTokenRefreshing eiste');
                            return from(this.isTokenRefreshing.pipe(
                                    filter((value) => value !== undefined),
                                    switchMap(
                                        (result) => {
                                            if (result) {
                                                console.log('Chiamata handlerequest 1');
                                                return timer(500).pipe(switchMap(() => this.handleRequest(request.clone({
                                                    setHeaders: {
                                                        Authorization: 'Bearer ' + this.localStorageService.getAccessToken(),
                                                    },
                                                }), next)));
                                            } else {
                                                return throwError(() => error);
                                            }
                                        }
                                    )
                                )
                            );
                        } else {
                            console.log('isTokenRefreshing non esiste');
                            this.isTokenRefreshing = new BehaviorSubject<boolean | undefined>(undefined);
                            return from(this.authService.refreshTokenForm(
                              this.localStorageService.getRefreshToken() ? this.localStorageService.getRefreshToken()! : '',
                                (!!this.localStorageService.getUser()?.username ? this.localStorageService.getUser()?.username  : ''),
                                GrantType.REFRESHTOKEN)).pipe(
                                distinctUntilChanged(),
                                mergeMap((result) => {
                                    this.localStorageService.setToken(result.access_token!, result.refresh_token!, result.expires_in!);
                                    this.isTokenRefreshing!.next(true);
                                    this.isTokenRefreshing = undefined;
                                    console.log('Chiamata handlerequest 2');
                                    return this.handleRequest(request.clone({
                                        setHeaders: {
                                            Authorization: 'Bearer ' + result.access_token,
                                        },
                                    }), next);
                                }),
                                catchError(tokenError => {
                                    if (tokenError.url.includes('token')) {
                                        this.isTokenRefreshing!.next(false);
                                        this.isTokenRefreshing = undefined;
                                        this.utilitiesService.openStyledSnackBar('Errore durante l\'aggiornamento della sessione, si prega di rieseguire la login. Grazie.',  SnackbarTypes.Error);
                                        this.localStorageService.cleanUser();
                                        this.serviziAnmcoService.stopIntervalRefreshCookie();
                                        this.router.navigateByUrl('/' + PathEnum.HOME);
                                        return throwError(() => error);
                                    } else {
                                        return throwError(() => error);
                                    }
                                }));
                        }
                    } else {
                        return throwError(() => error);
                    }
                }));
    }
}
