/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ContenutoMultimediale } from '../model/contenutoMultimediale';
import { EventoMultimediale } from '../model/eventoMultimediale';
import { EventoMultimedialeDTO } from '../model/eventoMultimedialeDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EventiMultimedialiService {

    protected basePath = 'https://dev-api.anmco.it/anmco';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Crea un evento multimediale.
     *
     * @param body Evento multimediale da aggiungere.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addEvento1(body: EventoMultimedialeDTO, observe?: 'body', reportProgress?: boolean): Observable<EventoMultimediale>;
    public addEvento1(body: EventoMultimedialeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventoMultimediale>>;
    public addEvento1(body: EventoMultimedialeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventoMultimediale>>;
    public addEvento1(body: EventoMultimedialeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addEvento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EventoMultimediale>('post',`${this.basePath}/api/eventi-multimediali`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Crea un nuovo contenuto e lo associa ad un moderatore/relatore.
     *
     * @param idModeratoreRelatore
     * @param descrizione
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiungiNuovoContenutoForm(idModeratoreRelatore: string, descrizione: string, observe?: 'body', reportProgress?: boolean): Observable<ContenutoMultimediale>;
    public aggiungiNuovoContenutoForm(idModeratoreRelatore: string, descrizione: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContenutoMultimediale>>;
    public aggiungiNuovoContenutoForm(idModeratoreRelatore: string, descrizione: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContenutoMultimediale>>;
    public aggiungiNuovoContenutoForm(idModeratoreRelatore: string, descrizione: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idModeratoreRelatore === null || idModeratoreRelatore === undefined) {
            throw new Error('Required parameter idModeratoreRelatore was null or undefined when calling aggiungiNuovoContenuto.');
        }

        if (descrizione === null || descrizione === undefined) {
            throw new Error('Required parameter descrizione was null or undefined when calling aggiungiNuovoContenuto.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idModeratoreRelatore !== undefined) {
            formParams = formParams.append('idModeratoreRelatore', <any>idModeratoreRelatore) as any || formParams;
        }
        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }

        return this.httpClient.request<ContenutoMultimediale>('put',`${this.basePath}/api/eventi-multimediali/contenuto`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di tutti gli eventi multimediali.
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEventi1(observe?: 'body', reportProgress?: boolean): Observable<Array<EventoMultimediale>>;
    public getEventi1(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventoMultimediale>>>;
    public getEventi1(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventoMultimediale>>>;
    public getEventi1(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EventoMultimediale>>('get',`${this.basePath}/api/eventi-multimediali`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di tutti gli eventi multimediali nei quali l&#x27;utente che ne fa richiesta risulta essere un moderatore o un relatore.
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEventiDiInteresse(observe?: 'body', reportProgress?: boolean): Observable<Array<EventoMultimediale>>;
    public getEventiDiInteresse(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EventoMultimediale>>>;
    public getEventiDiInteresse(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EventoMultimediale>>>;
    public getEventiDiInteresse(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EventoMultimediale>>('get',`${this.basePath}/api/eventi-multimediali/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce un evento multimediale.
     *
     * @param evento_id Identificativo dell&#x27;evento multimediale da restituire. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEvento(evento_id: string, observe?: 'body', reportProgress?: boolean): Observable<EventoMultimediale>;
    public getEvento(evento_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventoMultimediale>>;
    public getEvento(evento_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventoMultimediale>>;
    public getEvento(evento_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evento_id === null || evento_id === undefined) {
            throw new Error('Required parameter evento_id was null or undefined when calling getEvento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EventoMultimediale>('get',`${this.basePath}/api/eventi-multimediali/${encodeURIComponent(String(evento_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del file del materiale cercato nel bucket privato se l&#x27;utente è un relatore o un moderatore per l&#x27;evento multimediale a cui è associato il materiale.
     *
     * @param file_name Nome del file del materiale da cercare all&#x27;interno del bucket privato. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMediaInPrivateBucket1(file_name: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getMediaInPrivateBucket1(file_name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getMediaInPrivateBucket1(file_name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getMediaInPrivateBucket1(file_name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (file_name === null || file_name === undefined) {
            throw new Error('Required parameter file_name was null or undefined when calling getMediaInPrivateBucket1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/eventi-multimediali/download/${encodeURIComponent(String(file_name))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                responseType: 'blob' as 'json'
            }
        );
    }

    /**
     * Rimuove un evento multimediale.
     *
     * @param evento_id Identificativo dell&#x27;evento multimediale da rimuovere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeEvento1(evento_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public removeEvento1(evento_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public removeEvento1(evento_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public removeEvento1(evento_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (evento_id === null || evento_id === undefined) {
            throw new Error('Required parameter evento_id was null or undefined when calling removeEvento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/eventi-multimediali/${encodeURIComponent(String(evento_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna un evento.
     *
     * @param body Evento da aggiornare.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateEvento1(body: EventoMultimedialeDTO, observe?: 'body', reportProgress?: boolean): Observable<EventoMultimediale>;
    public updateEvento1(body: EventoMultimedialeDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EventoMultimediale>>;
    public updateEvento1(body: EventoMultimedialeDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EventoMultimediale>>;
    public updateEvento1(body: EventoMultimedialeDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateEvento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<EventoMultimediale>('put',`${this.basePath}/api/eventi-multimediali`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Carica un nuovo materiale (file) nel bucket privato. Ammesso che l&#x27;utente sia un relatore o un moderatore del relativo evento multimediale.
     *
     * @param id_contenuto
     * @param file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadMaterialeInContenutoForm(id_contenuto: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ContenutoMultimediale>;
    public uploadMaterialeInContenutoForm(id_contenuto: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContenutoMultimediale>>;
    public uploadMaterialeInContenutoForm(id_contenuto: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContenutoMultimediale>>;
    public uploadMaterialeInContenutoForm(id_contenuto: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_contenuto === null || id_contenuto === undefined) {
            throw new Error('Required parameter id_contenuto was null or undefined when calling uploadMaterialeInContenuto.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<ContenutoMultimediale>('post',`${this.basePath}/api/eventi-multimediali/upload/${encodeURIComponent(String(id_contenuto))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
