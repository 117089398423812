import {Injectable} from '@angular/core';
import {LocalStorageService} from "./local-storage.service";
import {interval, Subscription, tap} from "rxjs";
import {AuthService} from "../../api-clients/generated/services";

@Injectable({
  providedIn: 'root'
})
export class ServiziAnmcoService {
  private refreshCookie: Subscription | undefined;

  constructor(private localStorageService: LocalStorageService,
              private authService: AuthService) {
  }


  startGetCookie() {
    if (this.localStorageService.isBrowser() && this.localStorageService.getAccessToken()) {
      this.getCookie();
      this.startIntervalRefreshCookie();
    }
  }

  startIntervalRefreshCookie() {
    // ogni 4 ore
    this.refreshCookie = interval(1000 * 14400000).subscribe(() => {
      this.localStorageService.setServiziAnmco(null)
      if (this.localStorageService.getAccessToken()) {
        this.getCookie();
      } else {
        this.stopIntervalRefreshCookie();
      }
    })
  }

  private getCookie() {
    if (this.localStorageService.isBrowser() && this.localStorageService.getAccessToken()) {
      this.localStorageService.setServiziAnmco(null)
      this.authService.refreshSessionFromAnmco('response').pipe(
        tap((reponseLogin) => this.localStorageService.setServiziAnmco(reponseLogin?.headers?.get('Location') ? reponseLogin?.headers?.get('Location') : reponseLogin?.headers?.get('location'))),
      ).subscribe()
    }
  }

  stopIntervalRefreshCookie() {
    this.localStorageService.setServiziAnmco(null);
    this.refreshCookie?.unsubscribe();
  }
}
