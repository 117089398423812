import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {LocalStorageService} from "../shared/services/local-storage.service";
import {NgcCookieConsentService} from "ngx-cookieconsent";
import {Observable} from "rxjs";
import {ServiziAnmcoService} from "../shared/services/servizi-anmco.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  constructor(public localStorageService: LocalStorageService,
              private ngcCookieConsentService: NgcCookieConsentService,
              private serviziAnmcoService: ServiziAnmcoService,
              @Inject(PLATFORM_ID) private platformId: string) {
    this.localStorageService.setPlatformId(this.platformId)

  }

  ngOnInit(): void {
    if (this.localStorageService.isBrowser() && !this.ngcCookieConsentService.hasConsented()) {
      this.ngcCookieConsentService.open()
    }
    this.serviziAnmcoService.startGetCookie()

  }


}
