<div class="full-wh">
  <router-outlet></router-outlet>
</div>

<ng-container *ngIf="!!localStorageService.serviziAnmco">
  <iframe id="anmco-silent-login" [src]="localStorageService.serviziAnmco | safeUrl" width="1" height="1"
          style="display: none;"></iframe>
</ng-container>





