/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ContentDTO } from '../model/contentDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { PageContentsSearchResultsDTO } from '../model/pageContentsSearchResultsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ContentsService {

    protected basePath = 'https://dev-api.anmco.it/anmco';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce un contenuto, se abilitato, a partire dagli slug dei livelli superiori del suo percorso.
     *
     * @param macro_sezione_slug Slug di macro sezione valido. Non può essere nullo o vuoto.
     * @param sezione_slug Slug di sezione valido. Non può essere nullo o vuoto.
     * @param sotto_sezione_pagina_link_slug Slug di sotto sezione, pagina o link valido. Non può essere nullo o vuoto.
     * @param pagina_link_slug Slug di pagina o link valido.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContentOnline(macro_sezione_slug: string, sezione_slug: string, sotto_sezione_pagina_link_slug: string, pagina_link_slug?: string, observe?: 'body', reportProgress?: boolean): Observable<ContentDTO>;
    public getContentOnline(macro_sezione_slug: string, sezione_slug: string, sotto_sezione_pagina_link_slug: string, pagina_link_slug?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContentDTO>>;
    public getContentOnline(macro_sezione_slug: string, sezione_slug: string, sotto_sezione_pagina_link_slug: string, pagina_link_slug?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContentDTO>>;
    public getContentOnline(macro_sezione_slug: string, sezione_slug: string, sotto_sezione_pagina_link_slug: string, pagina_link_slug?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (macro_sezione_slug === null || macro_sezione_slug === undefined) {
            throw new Error('Required parameter macro_sezione_slug was null or undefined when calling getContentOnline.');
        }

        if (sezione_slug === null || sezione_slug === undefined) {
            throw new Error('Required parameter sezione_slug was null or undefined when calling getContentOnline.');
        }

        if (sotto_sezione_pagina_link_slug === null || sotto_sezione_pagina_link_slug === undefined) {
            throw new Error('Required parameter sotto_sezione_pagina_link_slug was null or undefined when calling getContentOnline.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (macro_sezione_slug !== undefined && macro_sezione_slug !== null) {
            queryParameters = queryParameters.set('macro_sezione_slug', <any>macro_sezione_slug);
        }
        if (sezione_slug !== undefined && sezione_slug !== null) {
            queryParameters = queryParameters.set('sezione_slug', <any>sezione_slug);
        }
        if (sotto_sezione_pagina_link_slug !== undefined && sotto_sezione_pagina_link_slug !== null) {
            queryParameters = queryParameters.set('sotto_sezione_pagina_link_slug', <any>sotto_sezione_pagina_link_slug);
        }
        if (pagina_link_slug !== undefined && pagina_link_slug !== null) {
            queryParameters = queryParameters.set('pagina_link_slug', <any>pagina_link_slug);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContentDTO>('get',`${this.basePath}/api/contents/online`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce un contenuto, se abilitato, a partire dagli slug dei livelli superiori del suo percorso.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContentPaths(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getContentPaths(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getContentPaths(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getContentPaths(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/api/contents/paths`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i contenuti di tipo pagina e/o contenuto esterno abilitati.
     *
     * @param search
     * @param slugs
     * @param categorie
     * @param keywords
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchContentsForm(search?: string, slugs?: Array<string>, categorie?: Array<string>, keywords?: Array<string>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageContentsSearchResultsDTO>;
    public searchContentsForm(search?: string, slugs?: Array<string>, categorie?: Array<string>, keywords?: Array<string>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageContentsSearchResultsDTO>>;
    public searchContentsForm(search?: string, slugs?: Array<string>, categorie?: Array<string>, keywords?: Array<string>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageContentsSearchResultsDTO>>;
    public searchContentsForm(search?: string, slugs?: Array<string>, categorie?: Array<string>, keywords?: Array<string>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (search !== undefined) {
            formParams = formParams.append('search', <any>search) as any || formParams;
        }
        if (slugs) {
            slugs.forEach((element) => {
                formParams = formParams.append('slugs', <any>element) as any || formParams;
            })
        }
        if (categorie) {
            categorie.forEach((element) => {
                formParams = formParams.append('categorie', <any>element) as any || formParams;
            })
        }
        if (keywords) {
            keywords.forEach((element) => {
                formParams = formParams.append('keywords', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageContentsSearchResultsDTO>('post',`${this.basePath}/api/contents/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
