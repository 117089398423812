import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ApiModule, BASE_PATH, Configuration} from "../api-clients/generated/services";
import {environment} from "../environments/environment";
import {LocalStorageService} from "../shared/services/local-storage.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "../shared/interceptor/auth.interceptor";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {HttpCancelInterceptor} from "../shared/interceptor/http-cancel.interceptor";
import {HttpCancelService} from "../shared/services/http-cancel-service.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import {PathEnum} from "./pages/path-enum";
import {InitializerService} from "../shared/services/initializer.service";
import {PipeModule} from "../shared/pipes/pipe.module";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";


const cookieConfig: NgcCookieConsentConfig = {
  "cookie": {
    "domain": environment.domain
  },
  "position": "bottom-right",
  theme: 'classic',
  type: 'info',
  "palette": {
    "popup": {
      "background": "#b71f24",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#032f58",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "content": {
    "message": "Questo sito utilizza cookie, anche di terze parti, per consentire la navigazione e offrire Servizi in linea con le Sue preferenze.  Chiudendo questo banner acconsenti all’uso dei cookie. Se vuole saperne di più o negare il consenso a tutti o ad alcuni cookie clicca qui.",
    "dismiss": "OK",
    "deny": "Refuse cookies",
    "link": "Leggi l'informativa",
    "href": environment.url + '/' + PathEnum.LEGALPRIVACY,
    "policy": "Cookie Policy"
  }
};


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}

];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', initialNavigation: 'enabled', }),
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    PipeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    LocalStorageService,
    HttpCancelService,
    InitializerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: InitializerService) =>
        () => configService.initApp(),
      deps: [InitializerService],
      multi: true
    },
    {
      provide: BASE_PATH,
      useValue: environment.servicesUrl,
    },
    {
      provide: Configuration,
      useFactory: (localStorageService: LocalStorageService) => {
        return new Configuration(
          {
            basePath: environment.servicesUrl,
            accessToken: () => {
              return localStorageService.getAccessToken();
            },
            withCredentials: true,
          });


      },
      deps: [LocalStorageService], multi: false,
    },],
  bootstrap: [AppComponent]
})
export class AppModule {
}
