/**
 * ANMCO Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di pagina
 */
export type PageType = 'GENERAL' | 'COURSE' | 'STRUCTURE' | 'EVENT' | 'EVENTI' | 'STUDI' | 'ATTI2019' | 'ATTI2020' | 'ATTI2021';

export const PageType = {
    GENERAL: 'GENERAL' as PageType,
    COURSE: 'COURSE' as PageType,
    STRUCTURE: 'STRUCTURE' as PageType,
    EVENT: 'EVENT' as PageType,
    EVENTI: 'EVENTI' as PageType,
    STUDI: 'STUDI' as PageType,
    ATTI2019: 'ATTI2019' as PageType,
    ATTI2020: 'ATTI2020' as PageType,
    ATTI2021: 'ATTI2021' as PageType
};