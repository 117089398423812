import {Injectable} from '@angular/core';
import {StruttureService, UrlRedirect, UrlRedirectService} from "../../api-clients/generated/services";
import {HttpClient} from "@angular/common/http";
import {Observable, ReplaySubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InitializerService {

  private redirectArray = new ReplaySubject<UrlRedirect[] | null>(1);

  constructor(private urlRedirectService: UrlRedirectService) {
  }

  initApp() {

    return new Observable((subscriber) => {
      this.urlRedirectService.getUrlRedirects().subscribe({
        next: value => {
          this.redirectArray.next(value)
          subscriber.complete();
        },
        error: err => {
          this.redirectArray.next([])
          subscriber.complete();
        }
      })

    });
  }

  get getRedirectArray():  ReplaySubject<UrlRedirect[] | null>{
    return this.redirectArray;
  }
}
