import {Injectable} from '@angular/core';
import * as moment from "moment";
import {Moment} from "moment";
import {isPlatformBrowser} from "@angular/common";
import {User} from "../../api-clients/generated/services";
import {BehaviorSubject, Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  platformId: string | undefined;
  private user$ = new BehaviorSubject(this.getUser());
  private token$ = new BehaviorSubject(this.getAccessToken());
  serviziAnmco: string = '';

  constructor() {

  }


  setOrarioChiusuraButton(orario: Moment) {
    if (this.isBrowser()) {
      localStorage.setItem(StorageProperties.ORARIO_CHIUSURA_BUTTON, orario.format('DD-MM-YYYT HH:mm'));
    }
  }

  removeOrarioChiusuraButton() {
    if (this.isBrowser()) {
      localStorage.removeItem(StorageProperties.ORARIO_CHIUSURA_BUTTON);
    }
  }

  getOrarioChiusuraButton() {
    let data = null;
    if (this.isBrowser()) {
      data = localStorage.getItem(StorageProperties.ORARIO_CHIUSURA_BUTTON);
    }
    return !!data ? moment(data, 'DD-MM-YYYT HH:mm') : null;
  }

  getAccessToken(): string {
    if (this.isBrowser()) {
      return localStorage?.getItem(StorageProperties.ACCESS_TOKEN) || '';
    }
    return '';
  }

  setPlatformId(platformId: string) {
    this.platformId = platformId;
  }

  isBrowser() {
    return this.platformId && isPlatformBrowser(this.platformId)
  }


  setToken(access_token: string, refresh_token: string, expiration: number) {
    if (this.isBrowser()) {
      localStorage.setItem(StorageProperties.ACCESS_TOKEN, access_token);
      localStorage.setItem(StorageProperties.REFRESH_TOKEN, refresh_token);
      localStorage.setItem(StorageProperties.EXPIRATION, JSON.stringify(expiration));
    }
  }


  setUser(response: User) {
    if (this.isBrowser()) {
      localStorage.setItem(StorageProperties.USER, JSON.stringify(response));
      this.user$.next(response);
    }
  }

  getUser(): User | null {
    if (this.isBrowser()) {
      const user = localStorage.getItem(StorageProperties.USER);
      return user ? JSON.parse(user) : null;
    }
    return null;
  }

  getUser$(): Observable<User | null> {
    this.user$.next(this.getUser());
    return this.user$.asObservable();
  }

  getToken$(): Observable<string | null> {
    this.token$.next(this.getAccessToken());
    return this.token$.asObservable();
  }

  cleanUser() {
    if (this.isBrowser()) {
      localStorage.removeItem(StorageProperties.ACCESS_TOKEN,);
      localStorage.removeItem(StorageProperties.REFRESH_TOKEN);
      localStorage.removeItem(StorageProperties.EXPIRATION);
      localStorage.removeItem(StorageProperties.USER);
      this.token$.next(this.getAccessToken());
      this.user$.next(this.getUser());
      this.deleteAllAnmcoCookies();
    }
  }

  getRefreshToken() {
    if (this.isBrowser()) {
      return localStorage?.getItem(StorageProperties.REFRESH_TOKEN) || '';
    }
    return null;
  }

  deleteAllAnmcoCookies() {
    if (this.isBrowser()) {
      let cookies = document.cookie.split(";");
      cookies = cookies.filter(cookie => !cookie.toLowerCase().includes('cookieconsent'))
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
  }

  setServiziAnmco(link: string | undefined | null) {
    if (link) {
      this.serviziAnmco = link
    }
  }


  getPrimoAccesso() {
    if (this.isBrowser()) {
      return localStorage?.getItem(StorageProperties.PRIMO_ACCESSO_AL_SITO) || '';
    }
    return null;
  }

  setPrimoAccesso() {
    if (this.isBrowser()) {
      localStorage.setItem(StorageProperties.PRIMO_ACCESSO_AL_SITO, 'NO');
    }
  }
}


export enum StorageProperties {
  ORARIO_CHIUSURA_BUTTON = 'ORARIOCHIUSURABUTTON',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  EXPIRATION = 'EXPIRATION',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  USER = 'USER',
  PRIMO_ACCESSO_AL_SITO = 'PRIMO_ACCESSO_AL_SITO',
}
